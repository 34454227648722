import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { IthunkAPI, iEmployee } from "../../types";
import { BearerToken } from "../../services/auth";
import { Auth } from "aws-amplify";
import {
  IEmployeeQueryParam,
  IEmployeeResponse,
} from "../../types/api/EmployeeApis";

export const fetchEmployeeList = createAsyncThunk<
  IEmployeeResponse,
  Partial<IEmployeeQueryParam>,
  IthunkAPI
>("User/fetchEmployeeList", async (params, thunkAPI) => {
  const { dispatch, rejectWithValue } = thunkAPI;
  //   const token = await dispatch(getToken());
  const token = BearerToken();
  try {
    let pageNo = 1;
    let allEmployees: iEmployee[] = [];
    let hasMorePages = true;
    while (hasMorePages) {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/employees/list`,
        {
          params: { ...params, pageNo },
          headers: {
            //   Authorization: `Bearer ${token}`,
            Authorization: token,
          },
        }
      );
      const { data } = response.data;
      hasMorePages =
        response.data.meta.page.currentPage < response.data.meta.page.lastPage;
      pageNo++;
      allEmployees = allEmployees.concat(data);
    }
    return {
      data: allEmployees,
    };
  } catch (err: any) {
    if (axios.isCancel(err)) {
      // Handle cancellation separately
      return rejectWithValue({ canceled: true });
    } else {
      // Handle other errors
      return rejectWithValue(err?.response?.data?.errorDescription);
    }
  }
});
