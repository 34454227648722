// rootReducer.js
import { combineReducers } from "redux";
import { workorderReducer } from "./slices/workorderSlice";
import filterReducer from "./slices/filterSlice";
import crewReducer from "./slices/crewSlice";
import equipmentReducer from "./slices/equipmentSlice";
import employeeReducer from "./slices/employeeSlice";
import { eq } from "lodash";

const rootReducer = combineReducers({
  workOrders: workorderReducer,
  filters: filterReducer,
  crew: crewReducer,
  equipment: equipmentReducer,
  employee: employeeReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
