import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  iWorkOrderListResponse,
  IthunkAPI,
  IWorkorderQueryParam,
  iWorkOrder,
  IWorkorderPost,
  IWorkOrderPostPayload,
  IWorkOrderListPostPayload,
  IWorkorderListPost,
} from "../../types";
import { BearerToken } from "../../services/auth";
import { Auth } from "aws-amplify";
import { clearWorkorderError } from "../slices/workorderSlice";

function parseYYYYMMDD(dateString: string): Date {
  // Extract parts
  const year = parseInt(dateString.slice(0, 4), 10);
  const month = parseInt(dateString.slice(4, 6), 10) - 1; // 0-based
  const day = parseInt(dateString.slice(6, 8), 10);

  return new Date(year, month, day);
}

function groupConsecutiveDates(dates: string[]): Array<[string, string]> {
  if (!dates?.length) return [];

  // Sort and group
  const sorted = [...dates].sort();
  const ranges: Array<[string, string]> = [];
  let start = sorted[0];
  let prev = parseYYYYMMDD(sorted[0]);

  for (let i = 1; i < sorted.length; i++) {
    const currDate = parseYYYYMMDD(sorted[i]);
    const expectedNext = new Date(prev);
    expectedNext.setDate(expectedNext.getDate() + 1);

    if (currDate.getTime() !== expectedNext.getTime()) {
      // Break current range
      ranges.push([start, sorted[i - 1]]);
      start = sorted[i];
    }
    prev = currDate;
  }
  ranges.push([start, sorted[sorted.length - 1]]);
  return ranges;
}

export const fetchWorkorderList = createAsyncThunk<
  iWorkOrderListResponse,
  Partial<IWorkorderQueryParam>,
  IthunkAPI
>("workorder/fetchWorkorderList", async (params, { rejectWithValue }) => {
  try {
    const token = BearerToken();
    let allWorkOrders: iWorkOrder[] = [];

    if (params.listOfDates?.length) {
      const dateRanges = groupConsecutiveDates(params.listOfDates);
      for (const [startDate, endDate] of dateRanges) {
        let pageNo = 1;
        let hasMorePages = true;
        while (hasMorePages) {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/workorders/list`,
            {
              params: {
                ...params,
                startDate,
                endDate,
                pageNo,
              },
              headers: { Authorization: token },
            }
          );
          const { data } = response.data;
          hasMorePages =
            response.data.meta.page.currentPage <
            response.data.meta.page.lastPage;
          pageNo++;
          allWorkOrders = allWorkOrders.concat(data);
        }
      }
    } else {
      let pageNo = 1;
      let hasMorePages = true;
      while (hasMorePages) {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/workorders/list`,
          {
            params: { ...params, pageNo },
            headers: { Authorization: token },
          }
        );
        const { data } = response.data;
        hasMorePages =
          response.data.meta.page.currentPage <
          response.data.meta.page.lastPage;
        pageNo++;
        allWorkOrders = allWorkOrders.concat(data);
      }
    }

    return { data: allWorkOrders };
  } catch (err: any) {
    if (axios.isCancel(err)) {
      return rejectWithValue({ canceled: true });
    } else {
      return rejectWithValue(err?.response?.data?.errorDescription);
    }
  }
});

export const postWorkOrder = createAsyncThunk<
  IWorkorderPost,
  IWorkOrderPostPayload,
  IthunkAPI
>("workorder/postWorkOrder", async (payload, thunkAPI) => {
  const { dispatch, rejectWithValue } = thunkAPI;
  dispatch(clearWorkorderError());
  let { data, id, params } = payload;
  const url = `${process.env.REACT_APP_API_URL}/workorders/${id}`;
  //if technician fields are empty string then replace them with null
  if (data.technician1EmployeeId === "") {
    data.technician1EmployeeId = null;
  }
  if (data.technician2EmployeeId === "") {
    data.technician2EmployeeId = null;
  }
  if (data.technician3EmployeeId === "") {
    data.technician3EmployeeId = null;
  }
  if (data.technician4EmployeeId === "") {
    data.technician4EmployeeId = null;
  }
  if (data.isUpdateTechniciansOnFutureWorkOrders) {
    params = { ...params, isUpdateTechniciansOnFutureWorkOrders: true };
  }

  try {
    // const token = await dispatch(getToken());
    const token = BearerToken();
    const response = await axios.post(url, data, {
      params: params,
      headers: {
        // Authorization: `Bearer ${token}`
        Authorization: token,
      },
    });
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error?.response?.data?.errorDescription);
  }
});

export const postWorkOrderList = createAsyncThunk<
  IWorkorderListPost,
  IWorkOrderListPostPayload,
  IthunkAPI
>("workorder/postWorkOrderList", async (payload, thunkAPI) => {
  const { dispatch, rejectWithValue } = thunkAPI;
  dispatch(clearWorkorderError());
  const url = `${process.env.REACT_APP_API_URL}/workorders/list`;
  let { data } = payload;
  //if technician fields are empty string then replace them with null
  data = data.map((workOrder) => {
    if (workOrder.technician1Employee?.id === "") {
      workOrder.technician1Employee = null;
    }
    if (workOrder.technician2Employee?.id === "") {
      workOrder.technician2Employee = null;
    }
    if (workOrder.technician3Employee?.id === "") {
      workOrder.technician3Employee = null;
    }
    if (workOrder.technician4Employee?.id === "") {
      workOrder.technician4Employee = null;
    }
    return workOrder;
  });
  try {
    // const token = await dispatch(getToken());
    const token = BearerToken();
    const response = await axios.post(url, data, {
      headers: {
        // Authorization: `Bearer ${token}`
        Authorization: token,
      },
    });
    let workOrderListResponse: IWorkorderListPost;

    workOrderListResponse = {
      isWorkOrderListPostSuccessful: true,
    };

    return workOrderListResponse;
  } catch (error: any) {
    return rejectWithValue(error?.response?.data?.errorDescription);
  }
});
